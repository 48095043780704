import Swiper from "swiper"
import { find } from "utils/dom"
import { gsap } from "gsap";



export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return

	console.log('Page home');

	function animaIntro() {
		//gsap.globalTimeline.pause();
	
		if (!sessionStorage.getItem('visited')) {
			setTimeout(function() {
				//console.log("chegou aqui");
				
				
	
				document.querySelector(".homeinitialize_end").classList.remove("hidden");
				document.querySelector(".homeinitialize_start").classList.add("hidden");
				sessionStorage.setItem('visited', 'true');
				window.location.reload();
				
			}, 2200); 
		} else {
			//gsap.globalTimeline.pause();

			
	
			document.querySelector(".homeinitialize_end").classList.remove("hidden");
			document.querySelector(".homeinitialize_start").classList.add("hidden");
		}
	}
	
	animaIntro();
	
	window.addEventListener("beforeunload", function() {
		sessionStorage.setItem('visited', 'true');
	});


	

	


	
	

	const swiper_banner = new Swiper(".swiper_banner", {
		slidesPerView: "auto",
		spaceBetween: 20,
		pagination: {
		  el: ".swiper-pagination",
		  clickable: true,
		},

		breakpoints: {
			220: {
				spaceBetween: 16,
				centeredSlides: false,
			},
					
			1024: {
				
				
				spaceBetween: 20,
				centeredSlides: false,
				

			},	

				

		},
	});









	const Swiper_carrosel_projetos = new Swiper(".Swiper_carrosel_projetos", {
		slidesPerView: "auto", 
		spaceBetween: 16,
		slideToClickedSlide:true,
		preloadImages: false,
		centeredSlides: false,
		


		


		

		
		
		breakpoints: {

			220: {

				slidesPerView: 2.1,
				
				spaceBetween: 12,

			},
			
			1024: {
				slidesPerView: 3,
				
				spaceBetween: 20,
				
			},
			

		},
		

		
	



	});

	const Swiper_carrosel_projetos2 = new Swiper(".Swiper_carrosel_projetos2", {
		slidesPerView: "auto", 
		spaceBetween: 16,
		slideToClickedSlide:true,
		preloadImages: false,
		centeredSlides: false,
		


		


		

		
		
		breakpoints: {

			220: {

				slidesPerView: 2.1,
				
				spaceBetween: 12,

			},
			
			1024: {
				slidesPerView: 3,
				
				spaceBetween: 20,
				
			},
			

		},
		

		
	



	});


	const completoBtn = document.getElementById('completoBtn');
		const ambienteBtn = document.getElementById('ambienteBtn');
		const completos = document.querySelectorAll('.completo');
		const ambientes = document.querySelectorAll('.Ambiente');
	
		function showCompletos() {
			try {

				completos.forEach(el => el.classList.remove('hidden'));
				ambientes.forEach(el => el.classList.add('hidden'));
				completoBtn.classList.add('active_button');
				ambienteBtn.classList.remove('active_button');

			} catch(error) {

				console.log("DEU ESSE ERRO",error)
			}
			
		}
	
		function showAmbientes() {

			try {
				completos.forEach(el => el.classList.add('hidden'));
				ambientes.forEach(el => el.classList.remove('hidden'));
				completoBtn.classList.remove('active_button');
				ambienteBtn.classList.add('active_button');
			} catch(error) {

				console.log("DEU ESSE ERRO",error)
			}
		}
	
		completoBtn.addEventListener('click', showCompletos);
		ambienteBtn.addEventListener('click', showAmbientes);
	
		
		showCompletos();




	// const gatilho_tags = document.querySelectorAll(".gatilho_tags");
    // const svg_gatilhos = document.querySelectorAll(".svg_gatilho");
    // const svg_item_gatilho = document.querySelectorAll(".svg_item_gatilho");
    // const gatilho_tags_hidden = document.querySelectorAll(".gatilho_tags_hidden");
    // const text_gatilho_black = document.querySelectorAll(".text_gatilho_black");
    // const imagem = document.querySelectorAll('.imagem');

    // // console.log("oi", imagem)

    // gatilho_tags_hidden.forEach(hidden => hidden.style.display = "none");

    // gatilho_tags.forEach((gatilho, index) => {
    //     gatilho.addEventListener("click", () => {
    //         const svg = svg_gatilhos[index];
    //         const hidden = gatilho_tags_hidden[index];
    //         const text_black = text_gatilho_black[index];
    //         const svg_gatilho = svg_item_gatilho[index];
    //         const img = imagem[index]

    //         console.log("Aqui estou", img)

    //         // if (hidden.style.display != "none") {
    //         //     svg.style.transform = "rotate(0deg)";
    //         //     //hidden.style.display = "none";
	// 		// 	hidden.classList.remove("active")
    //         //     text_black.classList.remove("text-gatilho-red");
    //         //     img.classList.remove("red-filter");
    //         //     svg.classList.remove("red-fill");
    //         // } else {
    //         //     svg.style.transform = "rotate(270deg)";
    //         //     //hidden.style.display = "block";
	// 		// 	hidden.classList.add("active")
    //         //     img.classList.add("red-filter");
    //         //     text_black.classList.add("text-gatilho-red");
    //         //     svg.classList.add("red-fill");
    //         // }

	// 		if (hidden.classList.contains("active")) {
	// 			svg.style.transform = "rotate(0deg)";
	// 			hidden.classList.remove("active");
	// 			//hidden.style.display = "none";
	// 			text_black.classList.remove("text-gatilho-red");
	// 			img.classList.remove("red-filter");
	// 			svg.classList.remove("red-fill");
	// 		} else {
	// 			svg.style.transform = "rotate(270deg)";
	// 			hidden.classList.add("active");
	// 			//hidden.style.display = "block";
	// 			img.classList.add("red-filter");
	// 			text_black.classList.add("text-gatilho-red");
	// 			svg.classList.add("red-fill");
	// 		}
    //     });
    // });

    // const gatilho_tags_showMore = document.querySelectorAll(".gatilho_tags_showMore");
    // const svg_gatilhos_showMore = document.querySelectorAll(".svg_gatilho_showMore");
    // const gatilho_tags_hidden_showMore = document.querySelectorAll(".gatilho_tags_hidden_showMore");

    // gatilho_tags_hidden_showMore.forEach(hidden => hidden.style.display = "none");
    // svg_gatilhos_showMore.forEach(svg => svg.style.transform = "rotate(180deg)");

    // gatilho_tags_showMore.forEach((gatilho, index) => {
    //     gatilho.addEventListener("click", () => {
    //         const svg = svg_gatilhos_showMore[index];
    //         const hidden = gatilho_tags_hidden_showMore[index];

    //         document.getElementById('questions').classList.toggle('marginHidden');

	// 		if (hidden.classList.contains("active")) {
	// 			svg.style.transform = "rotate(180deg)";
	// 			hidden.classList.remove("active");
	// 			//hidden.style.display = "none";
	// 		} else {
	// 			svg.style.transform = "rotate(360deg)";
	// 			hidden.classList.add("active");
	// 			//hidden.style.display = "block";
	// 		}
    //     });
    // });


	const gatilho_tags = document.querySelectorAll(".gatilho_tags");
	const svg_gatilhos = document.querySelectorAll(".svg_gatilho");
	const svg_item_gatilho = document.querySelectorAll(".svg_item_gatilho");
	const gatilho_tags_hidden = document.querySelectorAll(".gatilho_tags_hidden");
	const text_gatilho_black = document.querySelectorAll(".text_gatilho_black");
	const imagem = document.querySelectorAll('.imagem');

	// console.log("oi", imagem)

	gatilho_tags_hidden.forEach(hidden => hidden.style.maxHeight = "0");

	gatilho_tags.forEach((gatilho, index) => {
		gatilho.addEventListener("click", () => {
			const svg = svg_gatilhos[index];
			const hidden = gatilho_tags_hidden[index];
			const text_black = text_gatilho_black[index];
			const svg_gatilho = svg_item_gatilho[index];
			const img = imagem[index];

			console.log("Aqui estou", img);

			if (hidden.classList.contains("active")) {
				svg.style.transform = "rotate(0deg)";
				hidden.classList.remove("active");
				text_black.classList.remove("text-gatilho-red");
				img.classList.remove("red-filter");
				svg.classList.remove("red-fill");
			} else {
				svg.style.transform = "rotate(270deg)";
				hidden.classList.add("active");
				text_black.classList.add("text-gatilho-red");
				img.classList.add("red-filter");
				svg.classList.add("red-fill");
			}
		});
	});

	const gatilho_tags_showMore = document.querySelectorAll(".gatilho_tags_showMore");
	const svg_gatilhos_showMore = document.querySelectorAll(".svg_gatilho_showMore");
	const gatilho_tags_hidden_showMore = document.querySelectorAll(".gatilho_tags_hidden_showMore");

	gatilho_tags_hidden_showMore.forEach(hidden => hidden.style.maxHeight = "0");
	svg_gatilhos_showMore.forEach(svg => svg.style.transform = "rotate(180deg)");

	gatilho_tags_showMore.forEach((gatilho, index) => {
		gatilho.addEventListener("click", () => {
			const svg = svg_gatilhos_showMore[index];
			const hidden = gatilho_tags_hidden_showMore[index];

			document.getElementById('questions').classList.toggle('marginHidden');

			if (hidden.classList.contains("active")) {
				svg.style.transform = "rotate(180deg)";
				hidden.classList.remove("active");
			} else {
				svg.style.transform = "rotate(360deg)";
				hidden.classList.add("active");
			}
		});
	});





		
	




	







}