import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageDsSections from './pages/ds_sections.js'
import PageLogin from "./pages/Login";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import MenuFunc from "./modules/menu";
import Swiper from "swiper";
import mottaEquipeText from "./modules/converteditorjs";
import { find,findAll } from "utils/dom";


// import SvgUse from "./utils/SvgUse";

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()
MenuFunc()
mottaEquipeText()

// Pages
PageHome()
PageDsSections()
PageLogin()?.init()

// utils logs
logs()








const Swiper_carrosel_page_list = new Swiper(".Swiper_carrosel_page_list", {
    slidesPerView: "auto", 
    spaceBetween: 16,
    slideToClickedSlide:false,
    preloadImages: false,
    centeredSlides: false,
    

    
    

    
    
    breakpoints: {

        220: {

            slidesPerView: 2,
            
            
            
            spaceBetween: 12,



        },
        
        1024: {
            slidesPerView: 4,


            
            spaceBetween: 20,
            
        },
        

    }
    

    




});







const Swiper_projetos = new Swiper(".Swiper_projetos", {
    slidesPerView: "auto", 
    spaceBetween: 16,
    slideToClickedSlide:true,
    preloadImages: false,
    centeredSlides: false,
    


    


    

    
    
    breakpoints: {

        220: {

            slidesPerView: 2,
            
            spaceBetween: 12,

        },
        
        1024: {
            slidesPerView: 2.8,
            
            spaceBetween: 20,
            
        },
        

    }
    

    




});

const Swiper_itens_projeto = new Swiper(".Swiper_itens_projeto", {
    slidesPerView: "auto", 
    spaceBetween: 0,
    slideToClickedSlide:true,
    preloadImages: false,
    centeredSlides: false,



    navigation: {
        nextEl: '#scrollRightBtn',
        
      }, 
    


    


    

    
    
    breakpoints: {

        220: {

            slidesPerView: 2.1,
            
            spaceBetween: 0,

        },
        
        1024: {
            slidesPerView: 5,
            
            spaceBetween: 0,
            
        },
        

    }
    

    




});

const Swiper_footer_projetos = new Swiper(".Swiper_footer_projetos", {
    slidesPerView: "auto", 
    spaceBetween: 16,
    slideToClickedSlide:true,
    preloadImages: false,
    centeredSlides: false,
    loop: true,

    autoplay: {
        delay: 1,
        pauseOnMouseEnter: true,
        disableOnInteraction: false 
    },
    


    


    

    
    
    breakpoints: {

        220: {

            slidesPerView: 2.1,

            speed: 0,
            
            spaceBetween: 12,

        },

        720: {

            slidesPerView: 3,

            speed: 0,
            
            spaceBetween: 12,

        },
        
        1024: {
            slidesPerView: 5,

            speed: 3000,
            
            spaceBetween: 20,
            
        },
        

    }
    

    




});














function modalCases_fundament(){

    const bodyElement = document.querySelector('body');
    const htmlElement = document.documentElement;
    const closeBtn_mobal = document.querySelector("#close_fundamento_home"); 
    const modal = document.getElementById("myModal_fundamento_home");

    if(closeBtn_mobal) {

            
            closeBtn_mobal.addEventListener('click', () => {

                document.body.classList.remove('no-scroll');

                 modal.style.display = "none";
                 bodyElement.style.overflow = 'auto';
                 htmlElement.style.overflow = 'auto';
                 });     

    }

    
    

}





const destinos_fundamento_home = findAll('.js-destino-fundamento-home');
let currentImageIndex = 0;
let images = [];

destinos_fundamento_home.forEach((item, index) => {
    item.addEventListener('click', () => {
        images = Array.from(destinos_fundamento_home).map(item => item.querySelector('img').outerHTML);
        currentImageIndex = index;

        const modal_conteudo = document.getElementById("myModal_fundamento_home");
        const bodyElement = document.querySelector('body');
        const htmlElement = document.documentElement;

        bodyElement.classList.add('no-scroll');


        modal_conteudo.style.display = "block";
        modal_conteudo.scrollTop = 0;

        const conteudoModal = `
            <div class="relative inset-0 overflow-hidden flex h-screen items-center justify-center">
                <div class="min-w-[1000px] w-full max-h-[300px] overflow-y-hidden">
                    <div class="relative">
                        <button id="close_fundamento_home" class="absolute right-0 top-1/4 text-white transform -translate-y-1/4 z-10">
                            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="white" stroke-opacity="0.2"/>
                                <path d="M21.75 21.75L34.25 34.25" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M21.75 34.25L34.25 21.75" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                    </div>
                    <div class="px-56 sm:px-0 max-w-[1024px] sm:max-w-[480px] w-screen max-h-[800px] overflow-hidden h-screen flex items-center justify-between px-24 py-24 z-0">
                        <button id="prevImage" class="text-white transform bg-gray-200 p-2 z-10">
                            <svg class="rotate-180" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                            </svg>
                        </button>
                        <div id="modalImageContainer" class="flex flex-col items-center">
                            ${images[currentImageIndex]}
                        </div>
                        <button id="nextImage" class="text-white transform bg-gray-200 p-2 z-10">
                            <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        `;

        const modal = document.getElementById("myModal_fundamento_home");
        modal.querySelector(".modal-content_fundamento_home").innerHTML = conteudoModal;

        modalCases_fundament();

        document.getElementById('prevImage').addEventListener('click', () => {
            currentImageIndex = (currentImageIndex - 1 + images.length) % images.length;
            document.getElementById('modalImageContainer').innerHTML = images[currentImageIndex];
        });

        document.getElementById('nextImage').addEventListener('click', () => {
            currentImageIndex = (currentImageIndex + 1) % images.length;
            document.getElementById('modalImageContainer').innerHTML = images[currentImageIndex];
        });

        // Adicionando eventos de teclado
        document.addEventListener('keydown', (event) => {
            if (event.key === 'ArrowLeft') {
                currentImageIndex = (currentImageIndex - 1 + images.length) % images.length;
                document.getElementById('modalImageContainer').innerHTML = images[currentImageIndex];
            } else if (event.key === 'ArrowRight') {
                currentImageIndex = (currentImageIndex + 1) % images.length;
                document.getElementById('modalImageContainer').innerHTML = images[currentImageIndex];
            }
        });
    });
});

const imagem_modal = document.querySelectorAll(".img_modal");


    imagem_modal.forEach((img, index) => {

        const className = `${index + 1}`;
        console.log("classname", className);


        //img.classList.replace("img_modal", className);
        img.setAttribute("data-destino-modal", className);
    });

const js_project_img = findAll('.js-project_img');
let currentImageIndex2 = 0;
let images2 = [];

js_project_img.forEach((item, index) => {
    item.addEventListener('click', () => {
        images2 = Array.from(js_project_img).map(item => item.querySelector('img').outerHTML);
        currentImageIndex2 = index;

        const modal_conteudo = document.getElementById("myModal_fundamento_home");
        const bodyElement = document.querySelector('body');
        const htmlElement = document.documentElement;

        bodyElement.classList.add('no-scroll');
        modal_conteudo.style.display = "block";
        modal_conteudo.scrollTop = 0;

        const conteudoModal = `
            <div class="relative inset-0 overflow-hidden flex h-screen items-center justify-center">
                <div class="min-w-[1000px] w-full max-h-[300px] overflow-y-hidden">
                    <div class="relative">
                        <button id="close_fundamento_home" class="absolute right-0 top-1/4 text-white transform -translate-y-1/4 z-10">
                            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="white" stroke-opacity="0.2"/>
                                <path d="M21.75 21.75L34.25 34.25" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M21.75 34.25L34.25 21.75" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                    </div>
                    <div class="px-56 sm:px-0 max-w-[1024px] sm:max-w-[480px] w-screen max-h-[800px] overflow-hidden h-screen flex items-center justify-between px-24 py-24 z-0">
                        <button id="prevImage" class="text-white transform bg-gray-200 p-2 z-10">
                            <svg class="rotate-180" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                            </svg>
                        </button>
                        <div id="modalImageContainer" class="flex flex-col items-center">
                            ${images2[currentImageIndex2]}
                        </div>
                        <button id="nextImage" class="text-white transform bg-gray-200 p-2 z-10">
                            <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        `;

        const modal = document.getElementById("myModal_fundamento_home");
        modal.querySelector(".modal-content_fundamento_home").innerHTML = conteudoModal;

        modalCases_fundament();

        document.getElementById('prevImage').addEventListener('click', () => {
            currentImageIndex2 = (currentImageIndex2 - 1 + images2.length) % images2.length;
            document.getElementById('modalImageContainer').innerHTML = images2[currentImageIndex2];
        });

        document.getElementById('nextImage').addEventListener('click', () => {
            currentImageIndex2 = (currentImageIndex2 + 1) % images2.length;
            document.getElementById('modalImageContainer').innerHTML = images2[currentImageIndex2];
        });

        // Adicionando eventos de teclado
        document.addEventListener('keydown', (event) => {
            if (event.key === 'ArrowLeft') {
                currentImageIndex2 = (currentImageIndex2 - 1 + images2.length) % images2.length;
                document.getElementById('modalImageContainer').innerHTML = images2[currentImageIndex2];
            } else if (event.key === 'ArrowRight') {
                currentImageIndex2 = (currentImageIndex2 + 1) % images2.length;
                document.getElementById('modalImageContainer').innerHTML = images2[currentImageIndex2];
            }
        });
    });
});












